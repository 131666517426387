import React, { useContext, useEffect } from 'react';
import { Outlet } from "react-router";
import { useMatch } from 'react-router-dom';
import { HeaderContext } from 'stores/header';

// Global components
import Breadcrumbs from 'components/Breadcrumbs';

// Local components
import Menu from './Components/Menu';

// UI
import HeaderBar from 'components/HeaderBar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const AccountSettings = () => {
  const { title, setHeader } = useContext(HeaderContext);

  const isIndex = useMatch('account');

  useEffect(() => setHeader({ title: 'Account' }), []);

  return (
    <Container
      maxWidth="none"
      disableGutters
    >
      <HeaderBar title={title} />

      <Stack direction="column" alignItems="center" spacing={2}>
        <Breadcrumbs />

        <Container maxWidth="sm">
          {isIndex
            ? <Menu />
            : <Outlet />
          }
        </Container>
      </Stack>
    </Container>
  );
}

export default AccountSettings;
