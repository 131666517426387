import React, { useState, useContext } from 'react'
import Firebase from 'firebase/app';
import { Link } from 'react-router-dom'
import { OrganizationContext } from 'stores/organization';
import deleteLink from 'actions/links/deleteLink';

// Components
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const LinkActions = ({ linkId, linkData, canEdit, canDelete, isPrimary }) => {
  const { organizationId, organizationData } = useContext(OrganizationContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const deleteAction = () => deleteLink(linkId, linkData);

  const setPrimary = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .update({
        'link': linkId
      });

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={linkId}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={`/${organizationData.slug}/${linkId}`}>
          View
        </MenuItem>

        {canEdit &&
          <MenuItem component={Link} to={`/${organizationData.slug}/${linkId}/edit`}>
            Edit
          </MenuItem>
        }

        {canEdit && !isPrimary &&
          <MenuItem onClick={setPrimary}>
            Set primary
          </MenuItem>	
        }

        {canDelete &&
          <MenuItem onClick={() => setConfirmOpen(true)}>
            Delete
          </MenuItem>	
        }
      </Menu>
      
      <ConfirmDialog
        title="Delete Link?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteAction}
      >
        Are you sure you want to delete this link?
      </ConfirmDialog>
    </React.Fragment>
  )
}

export default LinkActions;
