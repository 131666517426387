import React, { useState, useEffect, useContext } from 'react';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';
import { HeaderContext } from 'stores/header';

// Utilities
import { canCreate, canEdit, canDelete } from 'utilities/member';

// Components
import List from './List';
import GroupForm from 'forms/Group';

// UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const GroupsPage = () => {
  const { setHeader } = useContext(HeaderContext);
  const { userId } = useContext(UserContext);
  const { organizationData } = useContext(OrganizationContext);

  const [ formOpen, setFormOpen ] = useState(false);

  useEffect(() => setHeader({ title: 'Groups' }), []);

  const toggleForm = () => setFormOpen(!formOpen);

  return (
    <Box sx={{ padding: (theme) => theme.spacing(2) }}>
      <Stack spacing={2}>
        {canCreate(organizationData.roles[userId]) &&
          <Stack>
            <Button onClick={toggleForm}>
              Create 
            </Button>
          </Stack>
        }

        <List
          canEdit={canEdit(organizationData.roles[userId])}
          canDelete={canDelete(organizationData.roles[userId])}
        />
      </Stack>

      <GroupForm
        open={formOpen}
        submit={toggleForm}
        cancel={toggleForm}
      />
    </Box>
  )
}

export default GroupsPage;
