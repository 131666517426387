import React, { useEffect, useContext, useReducer } from 'react';

// Organization
import { UserContext } from 'stores/user';

// Actions
import createOrganization from 'actions/organizations/createOrganization';
import updateOrganization from 'actions/organizations/updateOrganization';

// Form
import { initialState } from './Model';
import OrganizationFormContext from './Context';
import Reducer from './Reducer';
import Form from './Form';

import { SET_STATE } from './Constants';

const OrganizationForm = ({ id, data, submit, cancel, disabled }) => {
  const { userId } = useContext(UserContext);
  const [ state, dispatch ] = useReducer(Reducer, initialState());

  useEffect(() => {
    if (data)
      dispatch({
        type: SET_STATE,
        value: data,
      })
  }, [data]);

  const handleSubmit = (state) => {
    id
      ? updateOrganization(id, state)
      : createOrganization({
          ...state,
          roles: {
            [userId]: 'owner'
          },
        });

    submit(state);
  };

  return (
    <OrganizationFormContext.Provider value={{ state, dispatch }}>
      <Form
        id={id}
        data={data}
        submit={handleSubmit}
        cancel={cancel}
        disabled={disabled}
      />
    </OrganizationFormContext.Provider>
  );

};

export default OrganizationForm;
