import React, { useState, useContext } from 'react';
import Firebase from 'firebase/app';

// Data
import { GroupsContext } from 'stores/groups';

// Components
import Group from './Components/Group';
import GroupForm from 'forms/Group';
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const List = ({ canEdit, canDelete }) => {
  const { groupsLoading, groupsData } = useContext(GroupsContext);
  const [ editFormOpen, setEditFormOpen ] = useState(false);
  const [ editFormId, setEditFormId ] = useState();
  const [ editFormData, setEditFormData ] = useState();
  const [ deleteId, setDeleteId ] = useState(false);
  const [ deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const toggleForm = () =>
    setEditFormOpen(!editFormOpen);

  const handleEdit = (id, data) => {
    setEditFormId(id);
    setEditFormData(data);
    setEditFormOpen(true);
  };

  const handleRemove = (id) => {
    setDeleteId(id);
    setDeleteConfirmOpen(true);
  };

  const removeAction = () => {
    Firebase
      .firestore()
      .collection('groups')
      .doc(deleteId)
      .delete();

    setDeleteConfirmOpen(false);
  }

  return (
    <React.Fragment>
      <Stack spacing={2}>
        {groupsData.length
          ? groupsData.map((group) =>
              <Group
                key={group.id}
                id={group.id}
                data={group.data()}
                edit={handleEdit}
                canEdit={canEdit}
                remove={handleRemove}
                canDelete={canDelete}
              />
            )
          : <Typography>
              No groups
            </Typography>
        }
      </Stack>

      <GroupForm
        open={editFormOpen}
        id={editFormId}
        data={editFormData}
        submit={toggleForm}
        cancel={toggleForm}
      />

      <ConfirmDialog
        title="Delete group"
        open={deleteConfirmOpen}
        setOpen={setDeleteConfirmOpen}
        onConfirm={removeAction}
      >
        Do you really want to delete this group?
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default List;
