import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Fab from '@mui/material/Fab';

const MobileNavigation = () => {
  const navigate = useNavigate();

  const { slug } = useParams();

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
      }}
      elevation={3}
    >
      <BottomNavigation sx={{ justifyContent: 'space-around' }}>
        <BottomNavigationAction
          label="Dashboard"
          value="dashboard"
          icon={<HomeIcon />}
          onClick={() => navigate(`/${slug}`)}
        /> 

        <BottomNavigationAction
          label="Links"
          value="links"
          icon={<LinkIcon />}
          onClick={() => navigate(`/${slug}/links`)}
        /> 

        <Box sx={{ flexGrow: 1 }} />

        <Fab
          color="primary"
          aria-label="create link"
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: -10,
            left: 0,
            right: 0,
            margin: '0 auto',
          }}
          size="medium"
          onClick={() => navigate(`/${slug}/new`)}
        >
          <AddIcon />
        </Fab>

        <BottomNavigationAction
          label="Search"
          value="search"
          icon={<SearchIcon />}
          onClick={() => navigate(`/${slug}/search`)}
        /> 

        <BottomNavigationAction
          label="Analytics"
          value="analytics"
          icon={<AnalyticsIcon />}
          onClick={() => navigate(`/${slug}/analytics`)}
        /> 
      </BottomNavigation>
    </Paper>
  );
};

export default MobileNavigation;
