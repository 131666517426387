import React, { useState, useEffect } from 'react';
import Firebase from 'firebase/app';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const propsAreEqual = (prev, next) =>
  prev.data.when === next.data.when
  && prev.data.type === next.data.type
  && prev.data.value === next.data.value;

const DateTime = React.memo(({ data, save }) => {
  const [ mounted, setMounted ] = useState(false);
  const [ when, setWhen ] = useState(data.when ? data.when : 'before');
  const [ type, setType ] = useState(data.type ? data.type : 'date');
  const [ datetime, setDatetime ] = useState(data.value ? new Date(data.value.seconds * 1000) : new Date());

  useEffect(() => {
    if (mounted)
      save({
        when: when,
        type: type,
        value: Firebase.firestore.Timestamp.fromDate(datetime)
      });

    if (!mounted) setMounted(true);
  }, [when, type, datetime]);

  const handlePeriodChange = (e) =>
    setWhen(e.target.value);

  const handleTypeChange = (e) =>
    setType(e.target.value);

  const handleDateTimeChange = (e) => {
    setDatetime(e);
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
    >

      <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
        <Select
          name="when"
          onChange={handlePeriodChange}
          value={when}
          variant="standard"
          fullWidth
          required
        >
          <MenuItem value="before">Before</MenuItem>
          <MenuItem value="on">On</MenuItem>
          <MenuItem value="after">After</MenuItem>
        </Select>

        <Select
          name="type"
          onChange={handleTypeChange}
          value={type}
          variant="standard"
          fullWidth
          required
        >
          <MenuItem value="date">Date</MenuItem>
          <MenuItem value="time">Time</MenuItem>
          <MenuItem value="datetime">Date / Time</MenuItem>
        </Select>
      </Stack>

      {type === 'date' && 
        <DatePicker
          value={datetime}
          ampm={false}
          onChange={handleDateTimeChange}
          renderInput={(params) => <TextField {...params} variant="standard" sx={{ flex: 1 }} />}
          required
        /> 
      }

      {type === 'time' && 
        <TimePicker
          value={datetime}
          ampm={false}
          onChange={handleDateTimeChange}
          renderInput={(params) => <TextField {...params} variant="standard" sx={{ flex: 1 }} />}
          required
        /> 
      }

      {type === 'datetime' && 
        <DateTimePicker
          value={datetime}
          ampm={false}
          onChange={handleDateTimeChange}
          renderInput={(params) => <TextField {...params} variant="standard" sx={{ flex: 1 }} />}
          required
        /> 
      }
    </Stack>
  );
}, propsAreEqual);

export default DateTime;
