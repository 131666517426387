import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Data
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

// Components
import OrganizationForm from 'forms/organization';

// UI
import Title from 'components/Title';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const GeneralSettings = ({ test }) => {
  const [alert, setAlert] = useState(false);

	const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const { organizationData, organizationId } = useContext(OrganizationContext);
  const hasPermissions = ['owner'].includes(organizationData.roles[userId]);

  const handleCancel = () => navigate('/');

  const handleSubmit = (data) =>
    organizationData.slug !== data.slug
      ? navigate('/')
      : setAlert(true);
  
  return (
    <Stack direction="column" spacing={2}>
      <Title>
        General
      </Title>

      <OrganizationForm
        id={organizationId}
        data={organizationData}
        submit={handleSubmit}
        cancel={handleCancel}
        disabled={hasPermissions ? false : true}
      />

      <Snackbar open={alert} autoHideDuration={3000} onClose={() => setAlert(false)}>
        <Alert onClose={() => setAlert(false)} severity="success">
          Settings saved  
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default GeneralSettings;
