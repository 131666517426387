import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { analyticsGetCount } from 'services/analytics';

import CircularProgress from '@mui/material/CircularProgress';

const ClickCounter = React.memo(({ link }) => {
  const { isLoading, data } = useQuery(
    ['clicks', link],
    async () => { 
      const res = await analyticsGetCount({
        column: 'link',
        value: link,
      })

      return res.data[0]['value'];
    }
  );

  if (isLoading) return  <CircularProgress size={20} />

  return data;
});

export default ClickCounter;
