import react, { useState, useEffect, useContext } from 'react';
import Firebase from 'firebase/app';
import { UserContext } from 'stores/user';
import Invite from './Invite';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { userData } = useContext(UserContext);

  useEffect(() => {
    const unsubscribe = Firebase
      .firestore()
      .collection('invites')
      .where('email', '==', userData.email)
      .onSnapshot((snapshot) => setNotifications(snapshot.docs));

    () => unsubscribe();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    setOpen(open);
  };

  return (
    <>
      <IconButton
        aria-label="notifications"
        onClick={toggleDrawer(true)}
      >
        <Badge badgeContent={notifications.length ? notifications.length : 0} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{'& .MuiDrawer-paper': { width: 400, maxWidth: '70%' } }}
      >
        <Box p={2}>
          <Typography variant="body1" component="h1">
            Notifications
          </Typography>
        </Box>

        <Divider />

        <Box p={2}>
          {notifications.length
            ? notifications.map((doc) =>
              <Invite key={doc.id} id={doc.id} data={doc.data()} />
            )
            : <Alert severity="info">
                No notifications
              </Alert>
          }
        </Box>
      </Drawer>
    </>
  );
};

export default Notifications;
