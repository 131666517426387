import React, { useState } from 'react';
import { analyticsGetBySource } from 'services/analytics';

import { useQuery } from '@tanstack/react-query'
import { format, subDays } from 'date-fns';
import { ComposableMap, Geographies, Geography, ZoomableGroup, Sphere, Graticule } from "react-simple-maps";
import { scaleLinear } from "d3-scale";

import Card from 'components/Card';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { geography } from './world-110m';
import { grey } from '@mui/material/colors';

const colorScale = scaleLinear()
  .domain([0.1, 1])
  .range(["#ffedea", "#ff5233"]);

const CountryStats = ({ column, id, from, to }) => {
  const [ tooltipValue, setTooltipValue ] = useState('');

  if (!from && !to) {
    const now = new Date();
    from = subDays(now, 30);
    to = now;
  };

  const { isLoading, isError, data } = useQuery(
    [
      'countrystats',
      column,
      id,
      from,
      to,
    ],
    async () => {
      const res = await analyticsGetBySource({
        column: column,
        value: id,
        field: 'country',
        from: format(from, 'yyyy-MM-dd HH-mm'),
        to: format(to, 'yyyy-MM-dd HH-mm'),
      });

      return res.data;
    },
    {
      enabled: !!(column && id && from && to),
    }
  );

  return (
    <Card title="Visits by country">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Tooltip
            title={tooltipValue}
            followCursor
          > 
            <div>
              <ComposableMap
                projectionConfig={{
                  rotate: [-10, 0, 0],
                  scale: 147
                }}
              >
                <ZoomableGroup zoom={2}>
                  <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
                  <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
                  <Geographies geography={geography}>
                    {({ geographies }) =>
                      geographies.map(geo => {
                        const d = data ? data.find((s) => s.country === geo.properties.ISO_A2) : false;

                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill={d ? colorScale(d.value) : grey[200]}
                            onMouseEnter={() => {
                              if (d) {
                                const { NAME } = geo.properties;
                                setTooltipValue(`${NAME}: ${d.value}`);
                              }
                            }}
                            onMouseLeave={() => {
                              if (d) setTooltipValue("");
                            }}
                          />
                        );
                      })
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
            </div>
          </Tooltip>
        </Grid>

        <Grid item xs={12} md={4}>
          {!isLoading
            ? <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Country</TableCell>
                      <TableCell align="right">Clicks</TableCell>
                    </TableRow>
                  </TableHead>  

                  <TableBody>
                    {data && data.length
                      ? data.map((row) =>
                          <TableRow key={row.country}>
                            <TableCell component="th" scope="row">
                              {row.country}
                            </TableCell>
                            <TableCell align="right">
                              {row.value}
                            </TableCell>
                          </TableRow>
                        )
                      : <TableRow>
                          <TableCell component="th" scope="row" colSpan={2} align="center">
                            No data yet
                          </TableCell>
                        </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            : <Stack spacing={1}>
                <Skeleton variant="text" height={40} animation="wave" />
                <Skeleton variant="text" height={40} animation="wave" />
                <Skeleton variant="text" height={40} animation="wave" />
                <Skeleton variant="text" height={40} animation="wave" />
                <Skeleton variant="text" height={40} animation="wave" />
                <Skeleton variant="text" height={40} animation="wave" />
                <Skeleton variant="text" height={40} animation="wave" />
              </Stack>
            }
        </Grid>

      </Grid>
    </Card>
  );
};

export default CountryStats;
