import React, { useContext, useState } from 'react';
import OrganizationFormContext from './Context';

// Components
import Title from 'forms/components/Title';
import Slug from './Components/Slug';
import Website from './Components/Website';

// UI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const Form = ({ id, data, submit, cancel, disabled }) => {
  const { state, dispatch } = useContext(OrganizationFormContext);
  const [error, setError] = useState(false);

  const handleSetError = (value) => setError(value);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    submit(state);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Title
          data={state.title}
          dispatch={dispatch}
          disabled={disabled}
        />

        <Slug
          data={data ? data.slug : null}
          state={state.slug}
          dispatch={dispatch}
          error={error}
          setError={handleSetError}
          disabled={disabled}
        />

        <Website
          data={state.website}
          dispatch={dispatch}
          disabled={disabled}
        />

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          {!id &&
            <Button
              variant="contained"
              color="secondary"
              onClick={cancel}
            >
              Cancel
            </Button>
          }

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={error || JSON.stringify(data) === JSON.stringify(state)}
          >
            {id && 'Save'}
            {!id && 'Create'} 
          </Button>
        </Stack>
      </Stack>
    </form>
  )
};

export default Form;
