import React from 'react';
import { Link, useParams } from 'react-router-dom';

// Components
import OrganizationInfo from 'components/OrganizationInfo';

// UI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import GroupsIcon from '@mui/icons-material/Groups';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 250;

const Menu = React.memo(({ open, close }) => {
  const theme = useTheme();
  const { slug } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={close}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Toolbar>
            <Button
              component={Link}
              to="/"
              color="inherit"
              aria-label="navigate back"
              startIcon={<HomeIcon />}
              fullWidth
            >
              Home
            </Button>
          </Toolbar>

          <Divider />

          <Box sx={{ p: 2 }}>
            <OrganizationInfo />
          </Box>

          <Divider />

          <List onClick={close}>
            <ListItem component={ Link } to={`/${slug}`}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>

              <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem component={ Link } to="links">
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>

              <ListItemText primary="Links" />
            </ListItem>

            <ListItem component={ Link } to="groups">
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>

              <ListItemText primary="Groups" />
            </ListItem>

            <ListItem component={ Link } to="search">
              <ListItemIcon>
                <ManageSearchIcon />
              </ListItemIcon>

              <ListItemText primary="Search" />
            </ListItem>

            <ListItem component={ Link } to="analytics">
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>

              <ListItemText primary="Analytics" />
            </ListItem>

            <ListItem component={ Link } to="settings">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>

              <ListItemText primary="Settings" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
});

export default Menu;
