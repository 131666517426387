import React, { useState, useContext } from 'react';
import Firebase from 'firebase/app'
import { functions } from 'utilities/firebase';
import { useNavigate } from 'react-router-dom';
import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

// UI
import Title from 'components/Title';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InviteMember = () => {
  const [ loading, setLoading ] = useState(false);
  const [ inviteError, setInviteError ] = useState(false);
  const [ notifyError, setNotifyError ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ role, setRole ] = useState('editor');

  const { organizationId, organizationData } = useContext(OrganizationContext);
  const { userId, userData } = useContext(UserContext);

  const navigate = useNavigate();

  const invite = () =>
    Firebase
    .firestore()
    .collection('invites')
    .add({
      invitedBy: userId,
      createdOn: Firebase.firestore.Timestamp.now(),
      organization: organizationId,
      email: email,
      role: role,
    })

  const notify = () => functions
    .httpsCallable('sendMail')({
      to: email,
      subject: `Invitation to join ${organizationData.title}`,
      message: `
        Dear ${email}, <br /><br />

        You have been invited to join ${organizationData.title} organization by ${userData.email} on Lynky platfrom. <br /><br />

        Go to <a href="https://app.lynky.io">lynky.io</a> and log in to join.
      `
    })

  const handleEmailChange = (e) => {
    const { value } = e.target;
    const sanitezedValue = value.replace(/ /g,'');

    setEmail(sanitezedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const invited = await invite();

    if (!invite) {
      setLoading(false);
      setInviteError(true);
    }

    if (invited) {
      const notified = await notify();
      
      if (notified) navigate(-1);

      if (!notified) {
        setLoading(false);
        setNotifyError(true);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>
          Invite a member
        </Title>
      </Grid>

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12} sm={7}>
            <TextField
              type="email"
              id="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Insert email"
              variant="standard"
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <FormControl fullWidth>
              <InputLabel id="role-label">
                Role
              </InputLabel>

              <Select
                native
                labelId="role-label"
                id="role"
                onChange={(e) => setRole(e.target.value)}
                defaultValue="editor"
                variant="standard"
                fullWidth
                required
              >
                <option value="admin">Admin</option>
                <option value="editor">Editor</option>
                <option value="reader">Reader</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Grid container justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : <AddCircleIcon />}
            >
              {loading && 'Inviting'}
              {!loading && 'Invite'} 
            </Button>
          </Grid>
        </Box>
      </form>

      <Snackbar open={inviteError} autoHideDuration={5000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Something went wrong while inviting, try again later.
        </Alert>
      </Snackbar>

      <Snackbar open={notifyError} autoHideDuration={5000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          The user has been invited, but something went wrong while sending the invitation email.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default InviteMember;
