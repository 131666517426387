const initialState = () => {
  return {
    title: '',
    slug: '',
    website: '',
    status: 'active',
    affiliates: [],
  }
};

export { initialState }
