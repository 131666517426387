import Firebase from 'firebase/app';

import { prepareDocForCreate } from 'actions/helpers/firestoreHelpers'

const createOrganization = (values) => {
  // const { uid } = Firebase.auth().currentUser;

  // Create organization
  return Firebase.firestore()
    .collection('organizations')
    .add(prepareDocForCreate(values))
    .then((data) => data)
    .catch((error) => {
      alert(`Whoops, couldn't create the store: ${error.message}`)
    })
}

export default createOrganization;
