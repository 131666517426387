import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone'

import { EDIT_AVATAR } from '../../../Constants';

import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';

const baseStyle = {
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  textAlign: 'center',
  borderWidth: 2,
  borderRadius: '50%',
  borderStyle: 'solid',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderStyle: 'dashed',
  borderColor: 'blue'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const Upload = React.memo(({ dispatch }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    noDragEventsBubbling: true,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    maxSize: 5000000,
    onDrop: acceptedFile => {
      dispatch({
        type: EDIT_AVATAR,
        value: {
          type: 'image',
          source: 'upload',
          upload: acceptedFile[0],
          file: Math.random().toString(20).substr(2, 16),
          urls: {
            thumb: '',
            small: '',
            regular: '',
          },
        },
      });
    },
  });

	const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div {...getRootProps({style})}>
      <input {...getInputProps()} />

      <IconButton
        sx={{
          position: 'absolute',
          bottom: '8px',
          right: '30px',
          padding: '5px',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        size="small"  
        aria-label="edit"
        onClick={open}
      >
        <PublishIcon fontSize="small" />
      </IconButton>
    </div>
  )
});

export default Upload;
