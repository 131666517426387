import React, { useState, useContext } from 'react';
import Firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

// Data
import { OrganizationContext } from 'stores/organization';

// Components
import Title from 'components/Title';
import ConfirmDialog from 'components/ConfirmDialog';

// UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const OrganizationDelete = () => {
  const { organizationData, organizationId } = useContext(OrganizationContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
	const [user] = useAuthState(Firebase.auth());

	const navigate = useNavigate();

  const deleteOrganization = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .delete()
      .then(() => navigate('/'));

  return (
    <Container maxWidth="sm">
      <Title>
        Delete organization
      </Title>

      <Box mt={3}>
        {organizationData && organizationData.roles[user.uid] === 'owner'
          ?
            <React.Fragment>
              <Typography variant="body1">
                If you are here, we hope you know what you doing. In this section you can permanently remove:
              </Typography>

              <List>
                <ListItem>
                  - This organization and all its settings and data
                </ListItem>
                <ListItem>
                  - All links created inside this organization
                </ListItem>
                <ListItem>
                  - All organization and links statistics
                </ListItem>
                <ListItem>
                  - All members
                </ListItem>
              </List>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => setConfirmOpen(true)}>
                  Delete organization
              </Button>
            </React.Fragment>
          : <p>Only admins can access this section.</p>
        }
      </Box>

      <ConfirmDialog
        title={`Delete ${organizationData ? organizationData.title : 'organization'}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteOrganization}
      >
        This operation is irreversible, are you sure you want to delete this organization?
      </ConfirmDialog>
    </Container>
  );
};

export default OrganizationDelete;
