import React, { useState } from 'react';
import Firebase from 'firebase/app'
import slugify from 'slugify';

// UI
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

// Actions
import { SET_FIELD } from '../Constants';

// It can only contain letters, numbers and '_'
const createNewSlug = title =>
  slugify(title,
    {
      replacement: '',
      lower: true,
      remove: /[^a-zA-Z0-9_.]/
    }
  );

const checkIfSlugAvailable = async (slug) => {
  const query = await Firebase.firestore()
    .collection('organizations')
    .where('slug', '==', slug)
    .get();

  return query.empty;
}

const Slug = ({ data, state, dispatch, error, setError, disabled }) => {
  const [ loading, setLoading ] = useState(false);

  const updateSlug = (value) =>
    dispatch({
      type: SET_FIELD,
      value: {
        field: 'slug',
        value: value,
      }
    })

  const handleChange = async (e) => {
    setLoading(true);
    setError(true)

    const { value } = e.target;
    const newSlug = createNewSlug(value);

    updateSlug(newSlug);

    if (data !== value) {
      const isAvailable = await checkIfSlugAvailable(newSlug);

      isAvailable
        ? setError(false)
        : setError(true)
    } else {
      setError(false)
    }

    setLoading(false);
  };

  return (
    <TextField
      id="slug"
      label="Slug"
      onChange={handleChange}
      value={state}
      InputProps={{
        startAdornment: <InputAdornment position="start">lynky.to/</InputAdornment>,
        endAdornment: <InputAdornment position="end">{loading && <CircularProgress size={18} />}</InputAdornment>,
      }}
      error={error && !loading}
      helperText={error && !loading ? 'Not available' : 'It can only contain letters, numbers and "_"'}
      variant="standard"
      disabled={disabled}
      fullWidth
      required
    />
  );
};

export default Slug;
