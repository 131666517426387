import React, { useState, useEffect } from 'react';

import Static from './Static';
import Gradient from './Gradient';
import Image from './Image';

import { EDIT_BACKGROUND } from '../../../Constants';

import Modal from 'components/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Edit = React.memo(({ dispatch }) => {
  const [state, setState] = useState();
  const [open, setOpen] = useState(false);
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => setTab(newValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (data) => setState(data);
  const handleInsert = () => {
    dispatch({
      type: EDIT_BACKGROUND,
      value: state,
    });

    handleClose();
  }; 

  useEffect(() => setState(), []);

  return (
    <React.Fragment>
      <IconButton
        sx={{
          position: 'absolute',
          bottom: '10px',
          right: '50px',
          padding: '5px',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        size="small"  
        aria-label="edit"
        onClick={handleOpen}
      >
        <EditIcon fontSize="small" />
      </IconButton>

      <Modal
        isOpen={open}
        handleClose={handleClose}
        title="Background"
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          }}
          dividers
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ borderBottom: '1px solid #ccc' }}
          >
            <Tab label="Static" style={{ flex: 1 }} {...a11yProps(0)} />
            <Tab label="Gradient" style={{ flex: 1 }} {...a11yProps(1)} />
            <Tab label="Image" style={{ flex: 1 }} {...a11yProps(2)} />
          </Tabs>

          <TabPanel value={tab} index={0} style={{ flex: 1, padding: '1rem' }}>
            <Static onChange={handleChange} />
          </TabPanel>

          <TabPanel value={tab} index={1} style={{ flex: 1, padding: '1rem' }}>
            <Gradient onChange={handleChange} />
          </TabPanel>

          <TabPanel value={tab} index={2} style={{ flex: 1, padding: '1rem' }}>
            <Image onChange={handleChange} /> 
          </TabPanel>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleInsert}
            disabled={!state}
          >
            Insert
          </Button>
        </DialogActions>
      </Modal>
    </React.Fragment>
  );
});

export default Edit;
