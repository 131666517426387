import React, { useEffect, useContext } from 'react';
import { Outlet } from 'react-router'
import { useMatch } from 'react-router-dom'
import { HeaderContext } from 'stores/header';

import Menu from './Components/Menu';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const Settings = () => {
  const { setHeader } = useContext(HeaderContext);
  const isIndex = useMatch(':slug/settings');

  useEffect(() => setHeader({ title: 'Organization settings' }), []);

  return (
    <Container maxWidth="false" disableGutters>
      {isIndex
        ? <Menu />
        : <Grid container>
            <Grid item xs={0} md={3}>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                  borderRight: '1px solid #ccc'
                }}
              >
                <Menu />
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              <Container maxWidth="md">
                <Outlet />
              </Container>
            </Grid>
          </Grid>
      }
    </Container>
  )
}

export default Settings;
