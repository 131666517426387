import React, { useState, useEffect } from 'react';

import {
  DndContext,
  closestCenter,
  // KeyboardSensor,
  // PointerSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  SortableContext,
  // sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Choice from './Choice';

import {
  ADD_CHOICE,
  MOVE_CHOICE,
} from '../Constants';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReorderIcon from '@mui/icons-material/Reorder';
import DoneIcon from '@mui/icons-material/Done';

// TODO: Compare them better
const arePropsEqual = (prev, next) =>
  prev.data === next.data;

const Choices = ({ data, dispatch }) => {
  const [editing, setEditing] = useState(false);

  const sensors = useSensors(
    useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      }
    }),
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
			activationConstraint: {
        distance: 5,
      }
    }),
    // useSensor(PointerSensor),
    /*
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
    */
  );

  useEffect(() => {
    if (data.length === 1)
      setEditing(false);
  }, [data.length]);

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="h6" component="p">
          Choices
        </Typography>

        <Stack
          direction="row"
          spacing={1}
        >
          {data.length > 1 &&
            <Button
              variant="contained"
              color="secondary"
              startIcon={editing ? <DoneIcon /> : <ReorderIcon />}
              size="small"
              onClick={() => setEditing(!editing)}
            >
              {editing ? 'Done' : 'Edit'}
            </Button>
          }

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddBoxIcon />}
            size="small"
            onClick={() =>
              dispatch({
                type: ADD_CHOICE,
              })
            }
          >
            Add
          </Button>
        </Stack>
      </Stack>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(e) =>
          dispatch({ type: MOVE_CHOICE, value: e })
        }
      >
        <SortableContext
          items={data}
          strategy={verticalListSortingStrategy}
        >
          {data.map((choice) =>
            <Choice
              key={choice.id}
              id={choice.id}
              data={choice}
              editing={editing}
              dispatch={dispatch}
            />
          )}
        </SortableContext>
      </DndContext>
    </Stack>
  );
};

export default Choices;
