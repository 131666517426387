import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Firebase from 'firebase/app';

import { OrganizationContext } from 'stores/organization';
import { UserContext } from 'stores/user';

import Title from 'components/Title';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ConfirmDialog from 'components/ConfirmDialog';

const Leave = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { organizationData, organizationId } = useContext(OrganizationContext);
  const { userId } = useContext(UserContext);
	const navigate = useNavigate();

  const openConfirm = () => setConfirmOpen(true);

  const leaveOrganization = () =>
    Firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .set({
        roles: {
          [userId]: Firebase.firestore.FieldValue.delete()
        }
      }, { merge: true })
      .then(() => navigate('/'));

  return (
    <>
      <Container maxWidth="sm">
        <Stack direction="column" spacing={2}>
          <Title>
            Leave organization
          </Title>

          <Typography variant="body1">
            If you are here, we hope you know what you doing. In this section you can remove yourself from this organization, by doing so you will loose access to all of its links and data. All data you have created will remain.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={openConfirm}
          >
              Leave
          </Button>
        </Stack>
      </Container>

      <ConfirmDialog
        title={`Leave ${organizationData ? organizationData.title : 'organization'}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={leaveOrganization}
      >
        This operation is irreversible, are you sure you want to leave this organization?
      </ConfirmDialog>
    </>
  );
};

export default Leave;
