import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Components
import HeaderBar from 'components/HeaderBar';
import OrganizationForm from 'forms/organization';

// UI
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const NewOrganization = () => {
	const navigate = useNavigate();
  const handleSubmit = () => navigate('/');
  const handleCancel = () => navigate('/');

  return (
    <Container maxWidth="none" disableGutters>
      <HeaderBar title="New organization">
        <IconButton
          component={Link}
          to="/"
          color="inherit"
          aria-label="navigate back"
        >
          <ArrowBackIosIcon />
        </IconButton>
      </HeaderBar>

      <Container maxWidth="sm" sx={{ paddingTop: 1 }}>
        <OrganizationForm
          submit={handleSubmit}
          cancel={handleCancel}
          disabled={false}
        />
      </Container>
    </Container>
  );
};

export default NewOrganization;
