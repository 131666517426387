import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Menu = () =>
  <Box component={Paper} variant="outlined">
    <List
      subheader={<ListSubheader>Account settings</ListSubheader>}
      disablePadding
    >
      <Divider component="li" />

      <ListItem disablePadding>
        <ListItemButton component={Link} to='profile'>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>

          <ListItemText primary="Profile" />
        </ListItemButton>
      </ListItem>

      <Divider component="li" />

      <ListItem disablePadding>
        <ListItemButton component={Link} to='subscription'>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>

          <ListItemText primary="Subscription" />
        </ListItemButton>
      </ListItem>

      <Divider component="li" />

      <ListItem disablePadding>
        <ListItemButton component={Link} to='usage'>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>

          <ListItemText primary="Usage" />
        </ListItemButton>
      </ListItem>

      <Divider component="li" />

      <ListItem disablePadding>
        <ListItemButton component={Link} to='delete'>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>

          <ListItemText primary="Delete account" />
        </ListItemButton>
      </ListItem>
    </List>
  </Box>

export default Menu;
