import React, { useState, useEffect } from 'react';
import { differenceInDays, subDays } from 'date-fns'
import { DayPicker } from 'react-day-picker';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import 'react-day-picker/dist/style.css';

const StatsRange = (props) => {
  const now = new Date();
  const maxDays = differenceInDays(now, props.createdOn.toDate())
  const ranges = [
    { label: '24h', value: 1 },
    { label: '1w', value: 7 },
    { label: '1m', value: 30 },
    { label: '1y', value: 365 },
    { label: 'all', value: maxDays },
  ];

  const [range, setRange] = useState(ranges[2]); // Default: 1m
  const [from, setFrom] = useState(subDays(now, range.value));
  const [to, setTo] = useState(now);
  const [customRange, setCustomRange] = useState({
    from: subDays(now, range.value),
    to: now,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Return the values to the parent component
  useEffect(() => {
    props.from(from);
    props.to(to);
  }, [from, to]);

  const handleRangeChange = (range) => {
    setRange(range);
    setFrom(subDays(to, range.value));
  }

  const handleCustomRange = () => {
    setRange('custom');
    setFrom(customRange.from);
    setTo(customRange.to);

    handleClose();
  }

  return (
    <React.Fragment>
      <ButtonGroup size="small">
        {ranges.map((value, index) =>
          <Button
            key={index}
            variant={value.label === range.label ? 'contained' : 'outlined'}
            onClick={() => handleRangeChange(value)}
            sx={{ fontSize: 12 }}
          >
            {value.label}
          </Button>
        )}

        <Button
          variant={range === 'custom' ? 'contained' : 'outlined'}
          aria-describedby={id}
          onClick={handleClick}
          sx={{ fontSize: 12 }}
        >
          Custom
        </Button>
      </ButtonGroup>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container justifyContent="center">
          <DayPicker
            mode="range"
            defaultMonth={subDays(now, 30)}
            selected={customRange}
            onSelect={setCustomRange}
          />
        </Grid>

        <Divider />

        <Grid container justifyContent="flex-end" style={{ padding: '10px' }}>
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            Close
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={handleCustomRange}
          >
            Apply
          </Button>
        </Grid>
      </Popover>
    </React.Fragment>
  );
};

export default StatsRange;
