import React, { useState, useContext } from 'react';
import Firebase from 'firebase/app'
import { Link } from 'react-router-dom';
import { UserContext } from 'stores/user';

import Notifications from 'views/Account/Notifications';
import AppBar from '@mui/material/AppBar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from 'boring-avatars';

const HeaderBar = React.memo(({ children, title }) => {
  const { userData } = useContext(UserContext);
  const [userMenu, setUserMenu] = useState(null);
  const userMenuOpen = (event) => setUserMenu(event.currentTarget);
  const userMenuClose = () => setUserMenu(null);
  const logOut = () => Firebase.auth().signOut();

  return (
    <React.Fragment>
      <AppBar position="relative">
        <Toolbar>
          <Stack direction="row" alignItems="center" spacing={1} flexGrow={1}>
            {children}

            <Typography variant="h6" component="h1">
              {title}
            </Typography>
          </Stack>

          <Box sx={{ flex: 1 }} /> 

          <Notifications />

					<Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={userMenuOpen}>
            <Avatar
              size={40}
              name={userData.displayName ? userData.displayName : userData.email}
              variant="beam"
            />
          </Button>
        </Toolbar>
      </AppBar>
      
			<Menu
				id="acount-menu"
				anchorEl={userMenu}
				keepMounted
				open={Boolean(userMenu)}
				onClose={userMenuClose}
			>
				<MenuItem component={ Link } to='/account'>
					Account
				</MenuItem>

				<MenuItem onClick={logOut}>
					Log out
				</MenuItem>
			</Menu>
    </React.Fragment>
  );
});

export default HeaderBar;
