import React, { useContext } from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Link } from "react-router-dom";

// Data
import { UserContext } from 'stores/user';
import { SubscriptionContext } from 'stores/subscription';
import { OrganizationsContext } from 'stores/organizations';

// Components
import Alerts from 'components/Alerts';
import HeaderBar from 'components/HeaderBar';
import OrganizationCard from 'components/OrganizationCard';

// UI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

const Home = () => {
  const { userId } = useContext(UserContext);
  const { subscriptionData } = useContext(SubscriptionContext);
  const { organizationsData } = useContext(OrganizationsContext);

  const [ invited ] = useCollection(
    Firebase
      .firestore()
      .collection('organizations')
      .where('status', '==', 'active')
      .where(`roles.${userId}`, 'in', ['admin', 'editor', 'reader'])
  );

  return (
    <Container
      maxWidth="none"
      disableGutters
    >
      <HeaderBar title="Lynky" />

      <Container maxWidth="md" sx={{ marginTop: "1rem" }}>

        <Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
          <Alerts />
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )', gap: 2 }}>

          <Card
            component={Link}
            to="/new"
            variant="outlined"
            sx={{
              height: '100%',
              textDecoration: 'none',
            }}
          >
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center' }}>
                <AddIcon fontSize="large" />

                <Typography variant="h6" component="h2">
                  Create new organization
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          {organizationsData && organizationsData.map((organization) =>
            <OrganizationCard
              user={userId}
              organization={organization}
              subscription={subscriptionData}
              key={organization.slug}
            />
          )}

          {invited && invited.docs.map((doc) => {
            const organization = doc.data();

            return (
              <OrganizationCard
                user={userId}
                organization={organization}
                subscription={subscriptionData}
                key={organization.slug}
              />
            )
          })}
        </Box>
      </Container>
    </Container>
  )
}

export default Home;
