import React, { useState, useEffect, useContext } from 'react';
import { Outlet } from 'react-router'
import { Link, useParams, useMatch } from 'react-router-dom'

// Data
import { HeaderContext } from 'stores/header';
import { GroupsContext } from 'stores/groups';
import { UserContext } from 'stores/user';
import { OrganizationContext } from 'stores/organization';

// Utilities
import { canEdit } from 'utilities/member';

// Components
import Info from './Info';

// UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';

const GroupPage = () => {
  const [ loading, setLoading ] = useState(true);
  const isAnalytics             = useMatch(':slug/groups/:id/analytics');
  const [ tab, setTab ]         = useState(isAnalytics ? 0 : 1);
  const [ group, setGroup ]     = useState();

  const { id }          = useParams();
  const { setHeader }   = useContext(HeaderContext);
  const { groupsData }  = useContext(GroupsContext);
  const { userId }      = useContext(UserContext);
  const { organizationData } = useContext(OrganizationContext);

  const handleTabChange = (event, tabIndex) => setTab(tabIndex);

  useEffect(() => {
    setHeader({ title: 'Loading' })

    const data = groupsData.find((group) => group.data().slug === id);
    
    if (data) {
      setGroup(data);
      setHeader({ title: data.data().title })
      setLoading(false);
    }
  }, []);

  if (loading)
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );

  return (
    <React.Fragment>
      <Box sx={{ bgcolor: grey[50] }}>
        <Info
          group={group}
          canEdit={canEdit(organizationData.roles[userId])}
        />

        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab
            component={Link}
            to="analytics"
            label="Analytics"
            id="analytics"
          />

          <Tab
            component={Link}
            to="links"
            label="Links"
            id="links"
          />
        </Tabs>
      </Box>

      <Outlet context={{group}} />
    </React.Fragment>
  )
}

export default GroupPage;
