import React from 'react';
import { Link } from 'react-router-dom';

import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        width: '100%',
        paddingX: 2,
        paddingY: 1,
        borderBottom: '1px solid #ccc',
      }}
    >
      {breadcrumbs.map(({ key, match, breadcrumb, }) =>
        <Link key={key} to={match.pathname}>
          {breadcrumb}
        </Link>
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
