import React, { useState, useContext, useCallback } from 'react';
import { Outlet } from 'react-router';
import { useNavigate, useMatch } from 'react-router-dom';

// Data
import { OrganizationProvider } from 'stores/organization';
import { GroupsProvider } from 'stores/groups';
import { UTMProvider } from 'stores/utms';
import { ParamsProvider } from 'stores/params';
import { LinksProvider } from 'stores/links';
import { HeaderContext } from 'stores/header';

// Components
import OrganizationLoading from 'components/OrganizationLoading';
import Alerts from 'components/Alerts';
import ResponsiveMenu from 'components/ResponsiveMenu';
import MobileNavigation from 'components/MobileNavigation';
import HeaderBar from 'components/HeaderBar';
import FabNewLink from 'components/FabNewLink';

// UI
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

const Organization = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = useCallback(() => setMenuOpen(!menuOpen));

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIndex = useMatch('/:slug');

  const { title } = useContext(HeaderContext);

  return (
    <OrganizationProvider>
      <GroupsProvider>
        <UTMProvider>
          <ParamsProvider>
            <LinksProvider>
              <OrganizationLoading>

              <ResponsiveMenu
                open={menuOpen}
                close={handleMenuToggle}
              />

              <Box
                component="main"
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  pb: () => isMobile ? 7 : 0,
                }}
              >
                <HeaderBar title={title}>
                  {isIndex && isMobile &&
                    <IconButton
                      label="Menu"
                      onClick={handleMenuToggle}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton> 
                  }

                  {!isIndex && isMobile &&
                    <IconButton
                      onClick={() => navigate(-1)}
                      color="inherit"
                      aria-label="navigate back"
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  }
                </HeaderBar>

                <Alerts />

                <Outlet />

                {!isMobile && <FabNewLink />}
              </Box>

              {isMobile && <MobileNavigation />}

              </OrganizationLoading>
            </LinksProvider>
          </ParamsProvider>
        </UTMProvider>
      </GroupsProvider>
    </OrganizationProvider>
  );
};

export default Organization;
