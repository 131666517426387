import React, { useEffect, useContext } from 'react';

import { OrganizationContext } from 'stores/organization';
import { HeaderContext } from 'stores/header';

// Components
import ClickStatsCard from 'components/cards/ClickStatsCard';
import LatestLinksCard from 'components/cards/LatestLinksCard';
import Card from 'components/Card';

// UI
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Dashboard = () => {
  const { organizationLoading, organizationError, organizationId } = useContext(OrganizationContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => setHeader({ title: 'Dashboard' }), []);

  if (organizationLoading) return <LinearProgress />;
  if (organizationError) return <p>Something went wront, try again later</p>;
  
  return (
    <Box sx={{ display: 'grid', gap: '1rem', padding: '1rem', }}>
      <Card title="Clicks in last 30 days">
        <ClickStatsCard
          column="organization"
          id={organizationId}
        />
      </Card>

      <LatestLinksCard />
    </Box>
  )
}

export default Dashboard
